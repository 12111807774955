<template>
  <div>
    <Result :imgUrl="paymentImg" title="还款成功" />
  </div>
</template>

<script>
import paymentImg from "@/assets/images/payment_success.png";
export default {
  name: "PaymentSuccess",
  data() {
    return {
      paymentImg,
    };
  },
};
</script>
